@import url('https://fonts.googleapis.com/css?family=Inconsolata');
@import url('https://fonts.googleapis.com/css?family=Lora');
@import url('https://fonts.googleapis.com/css?family=Junge');

.Main-header {
    font-family: 'Inconsolata';
    font-size: large;
    line-height: 1.3;
    /* width: 80%; */
     margin: 5%;
}
a:link { text-decoration: none; color: black; font-weight: normal; }


a:visited { text-decoration: none; color: black; font-weight: normal;}


a:hover { text-decoration: none; color: black; font-weight: normal;}


a:active { text-decoration: none; color: black; font-weight: normal;}

.button-1 {
    background-color: white;
    padding: 0.7rem 1.5rem;
    border: none;
    border-radius: 20px;
    box-shadow: 0 16px 32px 0 rgb(102 138 165 / 40%);
    font-family: "Inconsolata";
    font-size: small;
    margin-left: 40%;
    margin-top: 3%;
    margin-bottom: 3%;
    text-align: right;
    color: black;
}
.typing {
    font-family: 'Inconsolata';
    font-size: large;
    line-height: 1.3;
    /* width: 80%; */
    margin: 5%;
}