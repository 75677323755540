@import url('https://fonts.googleapis.com/css?family=Inconsolata');
@import url('https://fonts.googleapis.com/css?family=Lora');
@import url('https://fonts.googleapis.com/css?family=Junge');



.Landing {
  background-color: white;
}


.Landing-header {
    /* text-align: center; */
  background-color: white;
  /* min-height: 100vh; */
  /* display: flex; */
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-family: 'Junge';
  font-weight: 100;

  font-size: calc(10px + 2vmin);
  color: black;
}

.Landing-header h1{
    padding-top: 10%;
    padding-right: 10%;
    padding-left: 10%;
}

.Landing-header h2{
    font-size: calc(8px + 2vmin);
    color: rgba(33, 33, 33);
}


.headers {
    margin-top: 10em;
    text-align: center;
    align-items: center;
  justify-content: center;
}

.l-button {
    background-color: black;
    padding: 0.7rem 1.5rem;
    border: none;
    border-radius: 20px;
    box-shadow: 0 8px 32px 0 rgb(0 0 0 / 35%);
    font-family: "Inconsolata";
    color: aliceblue;
}

.button-div {
    text-align: center;
    align-items: center;
  justify-content: center;
    margin: 10%;
}


.regular {
  font: "Inconsolata";
}
.logo-div {
    padding-left: 1rem;
    padding-right: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    position: absolute;
    /* bottom: 0; */
    top:3%;
    /* width: 100%; */
    left: 0;
    right: 0;
    /* z-index: -100000000; */
}

.footer-div {
    padding-left: 1rem;
    padding-right: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    position: absolute;
    bottom: 0;
    /* width: 100%; */
    left: 0;
    right: 0;
    z-index: 100000000;
}